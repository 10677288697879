import request from '@/api/service'

const api = {
  // 经营日报
  getStatistics: '/pms/businessDaily/getStatistics',
  // 经营日报导出
  exportStatistics: '/pms/businessDaily/exportStatistics',
  // 客源日报
  getSourceAnalysis: '/pms/businessDaily/getSourceAnalysis',
  // 客源日报导出
  exportSourceAnalysis: '/pms/businessDaily/exportSourceAnalysis',
}

export function getStatistics(data) {
  return request({
    url: api.getStatistics,
    method: 'post',
    data,
  })
}

export function exportStatistics(params) {
  return request({
    url: api.exportStatistics,
    method: 'get',
    responseType: 'blob',
    params,
  })
}

export function getSourceAnalysis(data) {
  return request({
    url: api.getSourceAnalysis,
    method: 'post',
    data,
  })
}

export function exportSourceAnalysis(params) {
  return request({
    url: api.exportSourceAnalysis,
    method: 'get',
    responseType: 'blob',
    params,
  })
}
