<!-- 经营日报 -->
<template>
  <section class="cont businessDaily">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="flex">
          <div>
            <label class="m-right-15">营业时间</label>
            <el-date-picker v-model="date" type="date" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
             placeholder="选择日期" :clearable="false" @change="getData"></el-date-picker>
          </div>
          <div class="title">酒店营业日报</div>
          <div>
            <el-button type="primary" @click="exportStatistics"><span>导出明细</span></el-button>
          </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit stripe row-key="id" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column prop="type" label="类型/业务"></el-table-column>
          <el-table-column label="本日经营">
            <template slot-scope="scope">
              <div v-if="['客房总数','已售房数'].includes(scope.row.type) || ['关房数量','可售客房','全日房已售','钟点房已售'].includes(scope.row.business)">
                {{ scope.row.today }}
              </div>
              <div v-else-if="scope.row.type === '出租率'">{{ scope.row.today }}%</div>
              <div v-else>￥{{ scope.row.today }}</div>
            </template>
          </el-table-column>
          <el-table-column label="本月累计">
            <template slot-scope="scope">
              <div v-if="['客房总数','已售房数'].includes(scope.row.type) || ['关房数量','可售客房','全日房已售','钟点房已售'].includes(scope.row.business)">
                {{ scope.row.month }}
              </div>
              <div v-else-if="scope.row.type === '出租率'">{{ scope.row.month }}%</div>
              <div v-else>￥{{ scope.row.month }}</div>
            </template>
          </el-table-column>
          <el-table-column label="上月同期">
            <template slot-scope="scope">
              <div v-if="['客房总数','已售房数'].includes(scope.row.type) || ['关房数量','可售客房','全日房已售','钟点房已售'].includes(scope.row.business)">
                {{ scope.row.lastMonth }}
              </div>
              <div v-else-if="scope.row.type === '出租率'">{{ scope.row.lastMonth }}%</div>
              <div v-else>￥{{ scope.row.lastMonth }}</div>
            </template>
          </el-table-column>
          <el-table-column label="本年累计">
            <template slot-scope="scope">
             <div v-if="['客房总数','已售房数'].includes(scope.row.type) || ['关房数量','可售客房','全日房已售','钟点房已售'].includes(scope.row.business)">
                {{ scope.row.year }}
              </div>
              <div v-else-if="scope.row.type === '出租率'">{{ scope.row.year }}%</div>
              <div v-else>￥{{ scope.row.year }}</div>
            </template>
          </el-table-column>
          <el-table-column label="去年同期">
            <template slot-scope="scope">
              <div v-if="['客房总数','已售房数'].includes(scope.row.type) || ['关房数量','可售客房','全日房已售','钟点房已售'].includes(scope.row.business)">
                {{ scope.row.lastYear }}
              </div>
              <div v-else-if="scope.row.type === '出租率'">{{ scope.row.lastYear }}%</div>
              <div v-else>￥{{ scope.row.lastYear }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { dateFactory, exportFile } from '@/common/js/common'
  import { getStatistics, exportStatistics } from '@/api/pms/statistics/businessDaily'
  export default {
    name: 'businessDaily',
    data() {
      return {
        crumbs: new Map([['PMS'], ['统计中心'], ['经营日报']]),
        date: dateFactory.dateFormat(false),
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() >= Date.now()
          },
        },
        tableData: [],
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        let data = {
          hotelId: this.hotelInfo.id,
          beginDateStr: this.date,
          endDateStr: this.date,
        }
        getStatistics(data).then(({ records, success }) => {
          if (success) {
            let arr = []
            records.forEach((el,index)=>{
              if (el.type !== undefined) {
                arr.push( { id:index+1 ,...el} )
              } else {
                let name =  el.business
                arr[arr.length-1].children === undefined && (arr[arr.length-1].children = [])
                arr[arr.length-1].children.push({ id:index+1 , type: name , ...el })
              }
            })
            this.tableData = arr
          }
        })
      },
      // 报表导出
      exportStatistics() {
        let data = {
          hotelId: this.hotelInfo.id,
          beginDateStr: this.date,
          endDateStr: this.date,
        }
        exportStatistics(data).then((res) => {
          exportFile(res, '酒店营业日报')
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .businessDaily {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .title {
        margin-left: -270px;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
</style>
